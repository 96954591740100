<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Technische gegevens</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Technische gegevens
  </h3>
    

  <h4 class="title is-4">
    Hoofdaansluitingen, cv-ketels en heaters
  </h4>
  <p class="block">
    Onderstaand zijn per gebouw de locaties van de hoofdafsluitingen, cv-ketels en heaters gegeven. De locatie van de technische voorzieningen zijn op de plattegronden in bijlage 1 weergegeven. De sleutelbeheerder van de meterkasten is de operationeel coördinator. 
  </p>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Technische voorziening</th>
        <th>Zinkerweg 13a</th>
        <th>Havenweg 7</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Hoofdaansluiting elektriciteit</td>
        <td>Begane grond, naast de roldeur voorzijde, in de meterkast (3 zwarte hoofdzekeringen in het midden van de kast)</td>
        <td>Begane grond, naast de roldeur voorzijde, in de meterkast (3 zwarte hoofdzekeringen in het midden van de kast)</td>
      </tr>
      <tr>
        <td>Hoofdaansluiting gas</td>
        <td>Begane grond, naast de roldeur voorzijde, in de meterkast (gele handgreep, links bovenop gasmeter)</td>
        <td>Begane grond, naast de roldeur voorzijde, in de meterkast (gele handgreep, links bovenop gasmeter)</td>
      </tr>
    </tbody>
  </table>

  <h4 class="title is-4">
    Opslag gevaarlijke stoffen
  </h4>
  <p class="block">
    De KVGM-coördinator is verantwoordelijk voor de actuele veiligheidsinformatiebladen van de gebruikte stoffen. Deze kunnen op SharePoint geraadpleegd worden Op de werkplek zijn <a href="https://rphwaalwijk.sharepoint.com/:f:/r/sites/KVGMPubliek/Shared%20Documents/8.%20Werk%20Instructie%20Kaarten%20-%20Gevaarlijke%20Stoffen?csf=1&web=1&e=OyS2Q1">Werkplek Instructie Kaarten</a> (WIK) aanwezig. 
  </p>

  <h4 class="title is-4">
    Stikstofinstallatie
  </h4>
  <p class="block">
    Op de eerste verdieping Zinkerweg 13a is aan de achterzijde een stikstofinstallatie aanwezig. De installatie wordt uitgeschakeld d.m.v. een noodstop op de begane grond in het magazijn van de Zinkerweg 13a, rechts naast de roldeur achterzijde. Bij inschakelen van de noodstop blijft de installatie op druk. De druk kan afgelaten worden via het afvulpaneel op de buitenplaats. <br>
    Denk bij het betreden van de stikstofkamer aan een mogelijk hoog stikstofgehalte en ventileer de ruimte voor het betreden goed. Aan de buitenzijde van stikstofkamer is een zuurstofmeter aanwezig die een akoestisch en visueel (lamp) signaal geeft als het zuurstofgehalte in de stikstofkamer te laag is.
  </p>
  <p class="block">
    Op de buitenplaats achter Zinkerweg en Havenweg worden in een afgeschermde kooi stikstofflessen afgevuld en opgeslagen. Ook hier is een noodstop aanwezig. De medewerker werkplaats zet stikstofflessen voor de monteurs klaar in het magazijn aan de Havenweg.
  </p>

  <h4 class="title is-4">
    Klimaatkamer
  </h4>
  <p class="block">
    In de stikstofkamer is een klimaatkamer aanwezig voor het uitvoeren van testen. De klimaatkamer kan worden uitgeschakeld door middel van de schakelaar op het bedieningspaneel naast de toegangsdeur van de kamer.  In de klimaatkamer bevinden zich stikstofflessen. <br>
    De klimaatkamer is rondom voorzien van een veiligheidskoord. Indien aan dit koord getrokken wordt, klinkt er een claxon. 
  </p>

  <h4 class="title is-4">
    Luchtcompressor
  </h4>
  <p class="block">
    Op de vide van het magazijn van de Havenweg en achter de klimaatkamer op de Zinkerweg staat een luchtcompressor. Deze compressoren kunnen worden uitgeschakeld door middel van een werkschakelaar. Deze schakelaar is te vinden op de buitenwand, links naast de luchtdroger (geel apparaat).
  </p>

  <h4 class="title is-4">
    Ontruimingsinstallatie
  </h4>
  <p class="block">
    Om de medewerkers en bezoekers in het pand te alarmeren en een ontruiming tot stand te brengen, is op de Zinkerweg 13a een ontruimingsinstallatie aanwezig (slow-whoop installatie). Op de Havenweg 7 is een dergelijke installatie (nog) niet aanwezig. 
  </p>
  <div class="block">
    <p>
      <img src="../../assets/bhv-plan/melders-zinkerweg.webp"><br>
    </p>
    <p class="has-text-centered">
      <em>Melders Zinkerweg</em>
    </p>
  </div>

  <p class="block">
    Van links naar rechts:
  </p>
  <div class="content">
    <ul>
      <li>1e verdieping achteraan parkeerplaats zijde</li>
      <li>1e verdieping halverwege bij WCs</li>
      <li>1e verdieping bij straat zijde, bij trap directie kantoor</li>
      <li>Begane grond bij koffieautomaat (straat zijde bij roldeur)</li>
    </ul>
  </div>

  <h4 class="title is-4">
    Brandmeld- en sprinklerinstallatie
  </h4>
  <p class="block">
    In beide panden is geen brandmeld- of sprinklerinstallatie aanwezig. 
  </p>

  <h4 class="title is-4">
    Bluswatervoorziening
  </h4>
  <p class="block">
    Naast brandhaspels in beide panden zijn er nabij beide gebouwen op de openbare weg onuitputtelijke bluswatervoorzieningen voorhanden.
  </p>

  <h4 class="title is-4">
    Brandcompartimenten
  </h4>
  <p class="block">
    Er zijn geen brandcompartimenten aanwezig. 
  </p>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>