<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div
      class="modal"
      :class="{ 'is-active': visible }"
    >
      <div
        class="modal-background"
        @click.prevent="close"
      />
      <div
        ref="contentNode"
        class="modal-content"
      >
        <div class="box">
          <div class="columns is-mobile">
            <div class="column">
              <h3
                v-if="title"
                class="title is-5"
              >
                {{ title }}
              </h3>
            </div>
            <div
              v-if="dismissable"
              class="column is-narrow"
            >
              <button
                class="delete is-large"
                aria="close"
                @click.prevent="close"
              />
            </div>
          </div>
                
          <div class="block">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount} from 'vue'

const props = defineProps({
  visible: { type: Boolean, default: false },
  dismissable: { type: Boolean, default: true },
  title: { type: String, default: null }
})

const contentNode = ref(null)

const emit = defineEmits(['update:visible'])

const close = () => emit('update:visible', false)

// dismissing modal when clicking outside content (if dismissable)
const clickHandler = e => {
  if (!props.visible) {
    return
  }

  for (let node = e.target; node; node = node.parentNode) {
    if (node === contentNode.value) {
      return; // clicked inside content
    }
  }

  close()
}
watch(
  () => props.dismissable,
  dismissable => {
    if (dismissable) {
      document.addEventListener('mouseup', clickHandler)
    } else {
      document.removeEventListener('mouseup', clickHandler)
    }
  },
  { immediate: true }
)
onBeforeUnmount(() => document.removeEventListener('mouseup', clickHandler))
</script>
