<template>
  <div
    class="is-flex is-flex-direction-column is-clipped"
    style="height: 100vh;"
  >
    <div
      class="is-flex-grow-1"
      style="overflow-y: auto; overflow-x: hidden;"
    >
      <section class="section">
        <div class="container">
          <SecondaryActions
            v-model:dark-theme="darkTheme"
            :is-logged-in="loggedIn"
            :is-admin="isAdmin"
            @sign-out="signOut"
            @manage-persons="managePersonsModalVisible = true"
            @show-bhv-plan="showBhvPlan"
          />

          <h1 class="title">
            Aanwezig
          </h1>

          <p class="subtitle">
            Digitaal aanwezigheidsbord
          </p>

          <div v-if="!checkedRememberMeLogin &amp;&amp; !checkedSsoTokenLogin">
            <FontAwesomeIcon
              :icon="faSpinner"
              spin
            />
          </div>

          <div v-if="checkedRememberMeLogin || checkedSsoTokenLogin">
            <div class="columns is-hidden-mobile">
              <div class="column is-6">
                <LocationBox
                  v-for="location of leftLocations"
                  :key="location.id"
                  :location="location"
                  :favorite="favoriteLocationId === location.id"
                  @toggle-favorite="favoriteLocationId = location.id"
                />
              </div>
              <div class="column is-6">
                <LocationBox
                  v-for="location of rightLocations"
                  :key="location.id"
                  :location="location"
                  :favorite="favoriteLocationId === location.id"
                  @toggle-favorite="favoriteLocationId = location.id"
                />
              </div>
            </div>

            <div class="columns is-hidden-tablet">
              <div class="column is-12">
                <LocationBox
                  v-for="location of allLocations"
                  :key="location.id"
                  :location="location"
                  :favorite="favoriteLocationId === location.id"
                  @toggle-favorite="favoriteLocationId = location.id"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginModal
        :visible="loginModalVisible"
        :validating="validatingLogin"
        @submit="login"
      />

      <ManagePersonsModal
        v-if="isAdmin"
        v-model:visible="managePersonsModalVisible"
      />

      <BhvPlanModal
        v-model:visible="bhvPlanVisible"
      />
    </div>
    <footer class="footer m-0 p-1">
      <div class="is-flex">
        <div class="is-flex-grow-1" />
        <div class="div">
          <img
            src="./assets/logo.svg"
            style="height: 1.5em;"
          >
        </div>
        <div class="is-flex-grow-1" />
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed, watch, onUnmounted } from 'vue'
import store from './store'
import LocationBox from './components/LocationBox.vue'
import LoginModal from './components/LoginModal.vue'
import SecondaryActions from './components/SecondaryActions.vue'
import ManagePersonsModal from './components/ManagePersonsModal.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import BhvPlanModal from './components/BhvPlanModal.vue';


const props = defineProps({
  ssoToken: { type: String, default: null },
  ssoError: { type: String, default: null }
})


// presence
const presence = computed(() => store.getters['presence/presence'])
const favoriteLocationId = ref(store.getters['settings/favoriteLocationId'])
const favoriteLocations = computed(() => !(presence.value && favoriteLocationId.value) ? [] : presence.value.locations.filter(l => l.id === favoriteLocationId.value))
const otherLocations = computed(() => !presence.value ? [] : presence.value.locations.filter(l => !favoriteLocationId.value || l.id !== favoriteLocationId.value))

const allLocations = computed(() => favoriteLocations.value.concat(otherLocations.value))
const leftLocations = computed(() => {
  const left = []
  let i = 0
  for (const location of allLocations.value) {
    ++i
    if (i % 2 === 1) {
      left.push(location)
    }
  }
  return left
})
const rightLocations = computed(() => allLocations.value.filter(l => !leftLocations.value.includes(l)))

watch(
  () => presence.value,
  presence => {
    if (favoriteLocationId.value === null) {
      for (const location of presence.locations) {
        favoriteLocationId.value = location.id
        return
      }
    }
  }
)
watch(
  () => favoriteLocationId.value,
  locationId => { store.dispatch('settings/updateFavoriteLocationId', locationId) }
)


// presence refresh
const refreshTimeout = ref(null)
const refreshPresence = (msec) => {
  if (loggedIn.value) {
    store.dispatch('presence/loadPresence')
  }
  refreshTimeout.value = window.setTimeout(() => refreshPresence(msec), msec)
}
const REFRESH_TIME_MS = 60 * 1000 // refresh presence every minute
window.setTimeout(() => refreshPresence(REFRESH_TIME_MS), REFRESH_TIME_MS)
onUnmounted(() => { window.clearTimeout(refreshTimeout.value) })


// login
const loggedIn = computed(() => store.getters['login/loggedIn'])
const validatingLogin = ref(false)
const checkedRememberMeLogin = ref(false)
const checkedSsoTokenLogin = ref(false)

const isAdmin = computed(() => store.getters['login/isAdmin'])
const loginModalVisible = computed(() => !loggedIn.value && (checkedRememberMeLogin.value || checkedSsoTokenLogin.value))
const login = ({ username, password, rememberMe }) => {
  store
    .dispatch('login/login', { username, password, rememberMe })
    .then(() => {
      validatingLogin.value = false
    })
    .catch(() => {
      validatingLogin.value = false
    })
}
const signOut = () => { store.dispatch('login/logout') }
watch(
  () => loggedIn.value,
  loggedIn => {
    if (loggedIn) {
      store.dispatch('presence/loadPresence')
      store.dispatch('presence/registerForUpdates')
    } else {
      store.dispatch('presence/unregisterForUpdates')
      store.dispatch('presence/clearPresence')
    }
  }
)
if (props.ssoToken) {
  store.dispatch('login/loginSsoToken', { ssoToken: props.ssoToken })
    .then(() => checkedSsoTokenLogin.value = true)
    .catch(() => checkedSsoTokenLogin.value = true)
}
if (props.ssoError) {
  store.dispatch('login/setErrorMessage', props.ssoError)
}
store.dispatch('login/loginRememberMe')
  .then(() => checkedRememberMeLogin.value = true)
  .catch(e => {
    console.error(e)
    checkedRememberMeLogin.value = true
  })



// dark mode
const darkTheme = ref(false)
watch(
  () => darkTheme.value,
  isDark => {
    if (isDark) {
      document.body.classList.add('has-dark-theme')
    } else {
      document.body.classList.remove('has-dark-theme')
    }
  },
  { immediate: true }
)


// Admin: Manage Persons
const managePersonsModalVisible = ref(false)


// BHV-plan
const bhvPlanVisible = ref(false)
const showBhvPlan = () => { bhvPlanVisible.value = true }
</script>

<style scoped>
.locations-move {
  transition: transform 1s;
}

.locations-enter-active,
.locations-leave-active {
  transition: all 1s;
}

.locations-enter,
.locations-leave-to {
  opacity: 0;
  transform: scale(.8);
}

.divider-overlay {
  font-size: 80%;
  display: block;
  margin-top: calc(-.75em);
}

.divider-overlay span {
  font-weight: bold;
  padding-left: 1ex;
  padding-right: 1ex;
  display: inline-block;
  background-color: white;
  border: 1px solid white;

  text-transform: uppercase;
}

footer {
  border-top: 1px solid var(--bulma-grey-lighter);
}
</style>
