<template>
  <div
    class="modal"
    :class="{ 'is-active': visible }"
  >
    <div
      class="modal-background"
      @click="close"
    />
    <div
      class="modal-content"
      @click.prevent
    >
      <div
        class="box is-flex is-flex-direction-column"
        style="height: calc(100vh - 40px);"
      >
        <div class="is-flex-shrink-0 block">
          <a
            class="delete is-pulled-right is-large"
            @click="close"
          />
          <h3 class="title is-5">
            Aanmelden
          </h3>
        </div>

        <Tabs
          v-model="form"
          class="is-flex-shrink-0"
          :options="tabOptions"
        />

        <!-- index -->
        <div class="is-flex-shrink-0 buttons is-multiline">
          <button
            v-for="item of nameIndex"
            :key="item.label"
            type="button"
            class="button is-small"
            :disabled="!item.elementId"
            @click="onIndexItem(item)"
          >
            {{ item.label }}
          </button>
        </div>

        <div
          :id="personListId"
          class="is-flex-grow-1"
          style="overflow-y: auto;"
        >
          <transition-group
            name="fade"
            tag="div"
            class="is-relative"
          >
            <div
              v-if="form === FORM_PERSON"
              key="person"
            >
              <span 
                v-if="loadingPersons" 
                class="icon"
              >
                <FontAwesomeIcon
                  :icon="faSpinner"
                  spin 
                />
              </span>

              <PersonAttendance
                v-for="person of persons"
                :id="personElementId(person)"
                :key="person.id"
                :name="person.name"
                :disabled="person.location_id && person.location_id === locationId"
                can-enter
                @enter="addPerson(person)"
              />
            </div>

            <div
              v-if="form === FORM_GUEST"
              key="guest"
            >
              <form @submit.prevent="submitGuest">
                <input 
                  ref="nameInput" 
                  v-model="guestName" 
                  class="input" 
                  type="text" 
                  placeholder="Voer naam in"
                >

                <div class="buttons is-right mt-5">
                  <button 
                    class="button is-dark is-pulled-right" 
                    :disabled="!canSubmit"
                    type="submit"
                    @click="submitGuest"
                  >
                    Toevoegen
                  </button>
                </div>
              </form>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import store from '../store'
import Tabs from './common/Tabs.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserCircle, faUserSecret, faSpinner } from '@fortawesome/free-solid-svg-icons'
import PersonAttendance from './PersonAttendance.vue'
import { generateId } from '../util/id'

const FORM_PERSON = 'person'
const FORM_GUEST = 'guest'

const props = defineProps({
  visible: { type: Boolean, default: false },
  locationId: { type: Number, required: true }
})

const emit = defineEmits(['update:visible', 'add'])

const form = ref('person')
const guestName = ref(null)
const nameInput = ref(null) // ref to name input HTML element

const canSubmit = computed(() => (guestName.value || '').trim() !== '')
const tabOptions = [
  { value: 'person', label: 'Medewerker', icon: faUserCircle },
  { value: 'guest', label: 'Gast', icon: faUserSecret }
]
const persons = computed(() => store.getters['presence/persons'])
const loadingPersons = computed(() => store.getters['presence/loadingPersons'])

const close = () => { emit('update:visible', false) }
const addPerson = person => { 
  store.dispatch('presence/enter', { person: person, location_id: props.locationId })
    .then(() => close())
}
const submitGuest = () => { 
  if (canSubmit.value) {
    store.dispatch('presence/enterGuest', { name: guestName.value, location_id: props.locationId })
      .then(() => close())
  }
}
const resetForm = () => { 
  form.value = FORM_PERSON
  guestName.value = null 
}

const personElementIds = {}
const personElementId = person => {
  personElementIds[person.id] = personElementIds[person.id] === undefined ? generateId() : personElementIds[person.id]
  return personElementIds[person.id]
}

const nameIndex = computed(() => {
  const index = []
  for(const letter of ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']) {
    const indexItem = { label: letter, elementId: null }
    for (const person of persons.value) {
      const personLetter = person.name.normalize('NFKD').replace(/\p{Diacritic}/gu, '').substring(0, 1).toUpperCase()
      if (personLetter === letter) {
        indexItem.elementId = personElementId(person)
        break
      }
    }

    index.push(indexItem)
  }
  
  return index
})

const personListId = generateId()
const onIndexItem = item => {
  if (!item.elementId) {
    return
  }

  document.getElementById(personListId).scrollTop = document.getElementById(item.elementId).offsetTop
}

watch(
  () => props.visible,
  visible => { 
    if (visible) { 
      resetForm()
      store.dispatch('presence/loadPersons')
    }
  },
  { immediate: true }
)
watch(
  () => form.value,
  form => {
    if (form === FORM_GUEST) {
      nextTick(() => nameInput.value.focus())
    }
  },
  { immediate: true }
)
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: all .3s;
}
.fade-leave-active {
  position: absolute;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(.8);
}

.person {
  border: 1px solid #80808033;
}

.modal .modal-content {
  height: calc(100vh - 40px);
}
</style>